<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <h5>Lista Necrologi</h5>
        </CCardHeader>

        <CCardBody>
          <CModal
              v-bind:title="titleModal"
              color="danger"
              centered
              fade
              :show.sync="warningModal"
              footer
              @update:show="closeModal"
          >
            {{ bodyModal }}
          </CModal>
          <CButton
              class="m-1 add-necrologio"
              size="lg"
              color="primary"
              @click="addNecrologio()"
          >
            Aggiungi Necrologio
          </CButton>
          <br>
          <br>
          <!-- https://coreui.io/vue/docs/components/table.html -->
          <CDataTable
              :items="loadedItems"
              :fields="fields"
              :table-filter="{ external: true, lazy: true,label:'Cerca',placeholder:'Inserisci nome' }"
              :table-filter-value.sync="tableFilterValue"
              :items-per-page="10"
              :active-page="1"
              outlined
              :noItemsView='{ noResults: "Nessun risultato da filtrare disponibile", noItems: "Nessun dato disponibile, o in caricamento" }'

              hover
              :loading="loading"
          >

            <template #fotoNecrologioCustom="{item}">
              <td>
                <CImg
                    v-bind:src="resolvePhoto(item)"
                    shape="rounded-circle"
                    width="124"
                    height="124"
                    className="mb-6"
                />
              </td>
            </template>

            <!--
            <template #statoPubblicazione="{item}">
              <td>
                <CBadge :color="getColoreStatoPubblicazione(item.stato_pubblicazione)">
                  {{ item.stato_pubblicazione }}
                </CBadge>
              </td>
            </template>
            -->

            <template #info="{item}">
              <td>
                <h6>Nome:
                  <div class="bold-text">{{ item.nome }}</div>
                </h6>
                <h6>Indirizzo:
                  <div class="bold-text"> {{ item.indirizzo }}</div>
                </h6>
                <h6>Data nascita:
                  <div class="bold-text">{{ item.data_nascita }}</div>
                </h6>
                <h6>Data morte:
                  <div class="bold-text">{{ item.data_morte }}</div>
                </h6>
              </td>
            </template>

            <template #creato="{item}">
              <td>
                <h6>{{ resolveDatetime(item.created_at) }}</h6>
              </td>
            </template>

            <template #aggiornato="{item}">
              <td>
                <h6>{{ resolveDatetime(item.updated_at) }}</h6>
              </td>
            </template>

            <template #visualizzazioni="{item}">
              <td>

                <h6 class="elenco-visualizzazioni" v-if="sumVisualizzazioni(item) > 0">Visualizzazioni Totali:
                  <div class="bold-text"> {{ sumVisualizzazioni(item) }}</div>
                </h6>

                <h6 class="elenco-visualizzazioni" v-if="item.visualizzazioni_sito > 0">Visualizzazioni Sito:
                  <div class="bold-text"> {{ item.visualizzazioni_sito }}</div>
                </h6>

                <h6 class="elenco-visualizzazioni" v-if="item.visualizzazioni_app > 0">Visualizzazioni App:
                  <div class="bold-text">{{ item.visualizzazioni_app }}</div>
                </h6>

              </td>
            </template>

            <template #comuni="{item}">
              <td>
                <h6>{{ item.comuni.comune }}</h6>
              </td>
            </template>

            <template #bottoneVisualizzazione="{item}">
              <td>
                <CButton
                    class="m-1"
                    size="md"
                    color="primary"
                    @click="edit(item)"
                >
                  Modifica
                </CButton>
                <CButton
                    class="m-1"
                    size="md"
                    color="danger"
                    @click="remove(item)"
                >
                  Cancella
                </CButton>
              </td>
            </template>
          </CDataTable>

          <CPagination
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
          />

        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>


<script>
const titleList = "Necrologi"
const bodyModal = "Attenzione! Sei sicuro di voler cancellare permanentemente questo necrologio?";
const titleModal = "Eliminazione Necrologio";
const apiList = '/dashboard/api/v0/necrologi/list';
const apiRemove = '/dashboard/api/v0/necrologi/delete/';

const fields = [
  {
    key: 'fotoNecrologioCustom',
    label: 'Foto',
    sort: false,
    filter: false
  },
  {
    key: 'info',
    label: 'Info',
    sort: false,
    filter: false
  },
  // {
  //   key: 'indirizzo',
  //   label: 'Indirizzo',
  //   sort: false,
  //   filter: false
  // },
  // {
  //   key: 'data_nascita',
  //   label: 'Data Nascita',
  //   sort: false,
  //   filter: false
  // },
  // {
  //   key: 'data_morte',
  //   label: 'Data Morte',
  //   sort: false,
  //   filter: false
  // },
  {
    key: 'comuni',
    label: 'Comuni in cui pubblicare',
    sort: false,
    filter: false
  },
  // {
  //   key: 'statoPubblicazione',
  //   label: 'Stato Pubblicazione',
  //   sort: false,
  //   filter: false
  // },
  {
    key: 'creato',
    label: 'Data Inserimento',
    sort: false,
    filter: false
  },
  {
    key: 'visualizzazioni',
    label: 'Visualizzazioni',
    sort: false,
    filter: false
  },
  {
    key: 'bottoneVisualizzazione',
    label: 'Operazioni',
    sort: false,
    filter: false
  },
]

export default {
  name: 'ListaNecrologi',
  data() {
    return {
      sorterValue: {column: null, asc: true},
      columnFilterValue: {},
      tableFilterValue: '',
      titleList: titleList,
      titleModal: titleModal,
      bodyModal: bodyModal,
      activePage: 1,
      loadedItems: [],
      itemsPerPage: 10,
      idDelete: null,
      loading: false,
      warningModal: false,
      pages: 0,
      currentPages: 1,
      fields: fields
    }
  },
  watch: {
    reloadParams() {
      this.onTableChange()
    }
  },
  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage
      ]
    }
  },
  methods: {
    onTableChange() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.getData();
      }, 1000)
    },
    sumVisualizzazioni(item) {
      return item.visualizzazioni_app + item.visualizzazioni_sito;
    },

    getData() {
      if (this.tableFilterValue.length > 0) {
        axios
            .get(apiList + '?search=' + this.tableFilterValue)
            .then((response) => {
              console.log("GET_DATA", response)
              if (response.status === 200) {
                this.loadedItems = response.data.results;
              }
            }, (error) => {
              console.log(error);
            });
      } else {
        axios
            .get(apiList + '?page=' + this.activePage)
            .then((response) => {
              console.log("GET_DATA", response)
              if (response.status === 200) {
                this.loadedItems = response.data.results;
              }
            }, (error) => {
              console.log(error);
            });
      }
    },

    edit(item) {
      this.$router.push({
        name: 'necrologio',
        params: {idNecrologio: item.id}
      });
    },

    addNecrologio() {
      this.$router.push({
        name: 'necrologio',
      });
    },

    /**
     * Quando la modale si apre e viene cliccato ok, viene confermata la cancellazione di una riga della
     * tabella sia lato front-end che back-end
     */
    closeModal(status, evt, accept) {
      if (accept) {
        axios
            .delete(apiRemove + this.idDelete)
            .then((response) => {
              this.getData();
              this.idDelete = null;
            }, (error) => {
              console.log(error);
            });
      }
    },

    /**
     * Cancellazione di un necrologio dalla lista e dal database
     * @param item
     */
    remove(item) {
      this.idDelete = item.id;
      this.warningModal = true;
    },

    /**
     * Risolve la foto seguendo l'object e concatenandosi con la cdn di stackpath
     * @param obj
     * @returns {string|*}
     */
    resolvePhoto(obj) {
      if (obj !== null && obj.foto !== null) {
        return process.env.VUE_APP_CDN_URL + obj.foto.foto_pagina_manifesto_mobile;
      } else {
        return obj.foto_profilo;
      }
    },

    /**
     * Ritorna la data in un formato front-end
     * @param datetimeObj
     * @returns {string}
     */
    resolveDatetime(datetimeObj) {
      return new Date(datetimeObj).toLocaleString()
    },

    /**
     * Ritorna un colore in base allo STATO di pubblicazione
     * @param status
     * @returns {string}
     */
    getColoreStatoPubblicazione(status) {
      switch (status) {
        case 'PUBBLICATO':
          return 'success'
        case 'VERIFICA':
          return 'warning'
      }
    },
  },

  created() {
    this.pages = 5
    this.getData();
  }
}
</script>
<style>
table.table.table-hover {
  margin-top: 24px;
  margin-bottom: 24px;
}

button.btn.m-1.btn-primary.btn-large {
  right: 10px;
  position: absolute;
  /* padding-right: 25px; */
}

button.btn-info {
  background-color: #670196;
}

button.btn.m-1.btn-primary.btn-large {
  background-color: #670196;
}

button.btn.m-1.btn-primary.btn-sm {
  background-color: #670196;
}

button.add-necrologio.btn-primary {
  right: 10px;
  position: absolute;
}

h6.elenco-visualizzazioni {
  display: flex;
}

.bold-text {
  font-weight: 700;
  margin-left: 1px;
}
</style>
